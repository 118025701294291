/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"} description={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."} seoTitle={"O nás - Chalupa Ořešák u Kouřimi"} ogTitle={"O nás - Chalupa Ořešák u Kouřimi"} ogDescription={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--parallax pt--80" style={{"marginBottom":0,"paddingBottom":94,"backgroundColor":"rgba(32,33,76,1)"}} name={"[[UNIsecname1]]-0"} layout={"l13"} parallax={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 flex--center" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}} animS={"3"}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} name={"[[UNIgenericsecname2]]"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 flex--center" style={{"maxWidth":""}} anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":3,"paddingBottom":0}} name={"u5hed1xttet"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"paddingTop":0,"marginTop":0,"marginBottom":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":609,"marginTop":0}} content={"<br>Marta je světoběžnice, která se po letech\n<br>strávených na cestách usadila a splnila si\ntak <br>svůj sen o \"babiččině chaloupce\".\n<br>~<br>Tu pečlivě zrekonstruovala do té podoby,\n<br>že se v ní budete cítit, jako když máte <br>na chvíli\nmožnost zastavit čas..\n<br>~\n<br>Věřím, že se k nám budete rádi vracet.. :)<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":0}}>
              
              <Image style={{"maxWidth":473,"marginBottom":0,"paddingBottom":0,"marginTop":16}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/80085ce6e2144544b6a0a9aa5c82fdf4_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/80085ce6e2144544b6a0a9aa5c82fdf4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/80085ce6e2144544b6a0a9aa5c82fdf4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/80085ce6e2144544b6a0a9aa5c82fdf4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/80085ce6e2144544b6a0a9aa5c82fdf4_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":609}} content={"<br>Majitelkou Martou je trpělivě renovováno\n<br>a vytvářeno stylové zázemí, které\npodtrhuje <br>ducha starého stavení.\n<br>A to nejen s dostatkem prostor\npro přespávání <br>a přebývání.\n<br>~\n<br>Blahodárná polosamota, absolutní klid, <br>nepřetržitý vábivý\nzpěv ptáků, bujná zeleň, <br>staré majestnátné\nstromy a klidná hladina rybníka, <br>za který\nzapadá slunce... to vše vám dobije baterky\n<br>na dlouho dopředu. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"paddingTop":2,"marginTop":0}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":609}} content={"<br>Vítáme Vás na naší chaloupce.\n<br>V domku ze 30. let minulého století, zrekonstruovaného\na zútulněného s pečlivým\npřihlédnutím k původním\na přírodním materiálům <br>a technologiím.\n<br>~\n<br>Čeká vás atmosféra starodávného stavení, které poskytuje pohodlné útočiště.\n<br>~\n<br>V kontaktu s původním člověk zůstává\nprostřednictvím zachovalých detailů,\nale i větších celků.\n<br>Původní jsou dřevěné podlahy, překládaný strop,\n<br>dveře i špaletová okna. Na starobylost\nstavby upozorňuje také starý\nzrenovovaný nábytek.<br>~<br>Nedaleká Kutná Hora, její impozantní <br>chrám sv. Barbory, Sedlecká Kostnice, ale také historická Kouřim jsou tipy pro kulturní výlety. <br>V okolí je také mnoho přírodních a turistických zajímavostí. Za návštěvu jistě stojí i aquapark <br>v Kolíně a nebo plovárna v Kutné Hoře. <br>Více info v sekci \"tipy na výlety\".<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":503}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/278752ac8dd14987a822fb7a7e2983dd_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/278752ac8dd14987a822fb7a7e2983dd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/278752ac8dd14987a822fb7a7e2983dd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/278752ac8dd14987a822fb7a7e2983dd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/278752ac8dd14987a822fb7a7e2983dd_s=1400x_.jpg 1400w"}>
              </Image>

              <Image style={{"maxWidth":503}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/93d0adab69484f239a25c7c24c6194c7_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/93d0adab69484f239a25c7c24c6194c7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/93d0adab69484f239a25c7c24c6194c7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/93d0adab69484f239a25c7c24c6194c7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/93d0adab69484f239a25c7c24c6194c7_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":609}} content={"Můžete si u nás zkusit vykouzlit dobroty\nvařením <br>na plotně a nebo pečením v troubě\n<br>v kachlových kamnech. <br>Posezení u kamen,\nze kterých sálá teplo <br>a praská\nv nich dřevo, je věc více než příjemná.\n<br>~\n<br>V případě deštivého počasí můžete nahlédnout\n<br>do naší vybavené knihovny a začíst se\n<br>do mnoha žánrů, kterými disponuje.\n<br>Najdete u nás také různé deskové\na společenské hry.\n<br>~\n<br>Ovšem TV u nás nehledejte, u nás preferujeme pospolitost a společně prožitý čas.\n<br>~\n<br>Rodinná dovolená v Česku, prázdniny s dětmi, společně strávený čas s přáteli, menší teambuilding a nebo prostor se sálem pro jógový víkend.<br>To vše tradiční chalupa Ořešák umí.<br>~<br>Poskytneme vám blahodárný\nklid pro relaxaci, <br>ale i\nvýborné podmínky pro turistiku, cyklistiku\n<br>a aktivní odpočinek v přírodě.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":4,"paddingBottom":0,"marginBottom":0}} layout={"l8"} name={"7plqtdc1r9x"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"marginTop":0}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/651f1e196fae4ede992f68c5ae78c890_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/651f1e196fae4ede992f68c5ae78c890_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/651f1e196fae4ede992f68c5ae78c890_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/651f1e196fae4ede992f68c5ae78c890_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/651f1e196fae4ede992f68c5ae78c890_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"paddingBottom":0,"marginBottom":0}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/114b3b9b543d473591e2410aea1cac29_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/114b3b9b543d473591e2410aea1cac29_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/114b3b9b543d473591e2410aea1cac29_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/114b3b9b543d473591e2410aea1cac29_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/114b3b9b543d473591e2410aea1cac29_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/98957f79793d4d9ab296e5d947072440_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/98957f79793d4d9ab296e5d947072440_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/98957f79793d4d9ab296e5d947072440_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/98957f79793d4d9ab296e5d947072440_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/98957f79793d4d9ab296e5d947072440_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" style={{"marginTop":6,"marginBottom":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/df798d8a0f8e49888c287571a88d9fd8_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/df798d8a0f8e49888c287571a88d9fd8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/df798d8a0f8e49888c287571a88d9fd8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/df798d8a0f8e49888c287571a88d9fd8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/df798d8a0f8e49888c287571a88d9fd8_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"marginBottom":10}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/8cd054f1de1d4fdfac7594355aff1bee_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/8cd054f1de1d4fdfac7594355aff1bee_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/8cd054f1de1d4fdfac7594355aff1bee_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/8cd054f1de1d4fdfac7594355aff1bee_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/8cd054f1de1d4fdfac7594355aff1bee_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/c87567b1044a4b009d9ca7d8b203dc68_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/c87567b1044a4b009d9ca7d8b203dc68_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/c87567b1044a4b009d9ca7d8b203dc68_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/c87567b1044a4b009d9ca7d8b203dc68_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/c87567b1044a4b009d9ca7d8b203dc68_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-z4x1f3 --parallax" style={{"paddingTop":0,"marginBottom":0,"paddingBottom":0}} name={"[[UNIsectionname3]]"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginBottom":0,"paddingBottom":157,"marginTop":0,"paddingTop":0}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" style={{"marginBottom":175,"paddingTop":0,"paddingBottom":0}} anim={"7"} animS={"2"}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40" style={{"backgroundColor":"var(--color-supplementary)","marginTop":0,"paddingTop":39}} name={"[[UNIsecname7]]"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":"","paddingTop":0}} anim={""} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">~ Přijeďte si k nám jen tak (po)být...<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":205}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">Nová Ves III 44 <br>Svojšice okr. Kolín 280 02<br>+420 608 859 625<br>chalupa.oresak@gmail.com<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}